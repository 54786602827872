import * as Sentry from "@sentry/browser";
import { env } from "tailwindcss/lib/lib/sharedState";

Sentry.init({
  dsn: "https://89ff1337c1750e7e623c3da5d25ceb31@o4504918812983296.ingest.us.sentry.io/4504953027624960",
  environment: env.process.NODE_ENV,
  denyUrls: ["localhost", "0.0.0.0"],
  initialScope: {
	tags : {
		"location": "frontend"
	}
  }
});
